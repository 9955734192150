import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { Layout } from "../components/Layout";
import { Carousel } from "../components/Carousel";
import { Button } from "../components/Button";
import { PageSeo } from "../components/Seo";
import { useSiteMetadata } from "../hooks/SiteMetadataQuery";
import { InviteProcessSection } from "../components/InviteProcessSection";
import { Copy } from "../components/Copy";
import { TestimonialsSection } from "../components/TestimonialsSection";
import { FinishingTouchesSection } from "../components/FinishingTouchesSection";
import { Container } from "../components/Page";

const HouseCollectionInvite = ({ data, pageContext }) => {
  const { getAQuoteUrl } = useSiteMetadata();
  const { prev, next } = pageContext;
  const { frontmatter: { title, thumbnail, images }, excerpt, html: content } = data.markdownRemark;
  return (
    <Layout>
      <PageSeo
        tabTitleSegments={["House Collection", title]}
        title={`${title} - Pre-designed Wedding Invite`}
        description={excerpt}
        imageUrl={thumbnail.childImageSharp.gatsbyImageData.images.fallback.src}
      />
      <Container>
        <div className="hidden sm:grid grid-cols-3 mb-2 text-gray-600 text-sm">
          <div className="text-left">
            {prev && <NavLink to={prev.fields.slug}>
              <FontAwesomeIcon icon={faChevronLeft} />
              &nbsp;{prev.frontmatter.title}
            </NavLink>}
          </div>
          <div className="text-center">
            <NavLink to="/weddings/house-collection">
              House Collection
            </NavLink>
          </div>
          <div className="text-right">
            {next && <NavLink to={next.fields.slug}>
              {next.frontmatter.title}&nbsp;
              <FontAwesomeIcon icon={faChevronRight} />
            </NavLink>}
          </div>
        </div>
      </Container>
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 sm:gap-6">
          <div className="md:col-span-2">
            <Carousel height={500} thumbnails={true} animate={false} autoPlay={false} indicators={false} items={images.map((image, i) => ({
              image: <GatsbyImage
                image={image.childImageSharp.main}
                alt={`${title} Image ${i + 1}`}
                loading="eager" />,
              thumb: <GatsbyImage
                image={image.childImageSharp.thumb}
                alt={`${title} Thumbnail Image ${i + 1}`} />,
            }))} />
          </div>
          <div className="order-first sm:order-last">
            <h1 className="text-4xl mb-6">{title}</h1>
            <Copy className="mb-6" dangerouslySetInnerHTML={{ __html: content }} />
            <div className="text-center">
              <Button
                as="a"
                className="text-xl p-4"
                rel="noreferrer"
                target="_blank"
                href={getAQuoteUrl}
              >
                Get a quote
              </Button>
            </div>
          </div>
        </div>
        <InviteProcessSection />
        <FinishingTouchesSection />
        <TestimonialsSection />
      </Container>
    </Layout>
  );
};

export default HouseCollectionInvite;

const NavLink = ({ to, children }) => {
  return (
    <Link className="inline-block p-4 underline" to={to}>
      {children}
    </Link>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: {slug: {eq: $slug}, collection: {eq: "house-collection"}}
    ) {
      frontmatter {
        title
        thumbnail {
          childImageSharp {
            gatsbyImageData (
              layout: FIXED
              width: 1200
              height: 627
            )
          }
        }
        images {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
              transformOptions: {cropFocus: CENTER}
            )
            main: gatsbyImageData(
              width: 1400
            )
          }
        }
      }
      excerpt: excerpt(format: PLAIN, pruneLength: 160, truncate: true)
      html
    }
  }`;
